.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 100%;
}

.App-link {
  color: #eff4f8;
}

.App-container {
  display: flex;
  flex: 1;
  width: 100%;
}

.Sidebar{
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

